import React from "react";

function Sidebar() {
  return (
    <div>
      <aside className="h-full  flex w-full" aria-label="Sidebar">
        <div className=" py-4 w-24 bg-[#0085ff] dark:bg-[#1c1c1c]">
          <ul className="space-y-2">
            <li>
              <div className="m-auto text-center items-center py-2  cursor-pointer text-base font-normal text-white hover:bg-[#0067C6] dark:hover:bg-gray-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-14 h-12 m-auto"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </div>
            </li>
            <li>
              <div className="m-auto text-center items-center py-3  cursor-pointer text-base font-normal text-white bg-[#0067C6] dark:bg-gray-700 ">
                {/* <svg
                  aria-hidden="true"
                  className="m-auto w-14 h-12 text-white transition duration-75 color-white "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg> */}
                <img src="img/chart.png" className="m-auto" width="52px"/>
                <span className="text-center">Charts</span>
              </div>
            </li>
          </ul>
        </div>
        
      </aside>
    </div>
  );
}

export default Sidebar;
