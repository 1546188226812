import React,{ useState } from "react";
import Switcher from "../container/ThemeSwitcher";
import { ethers } from "ethers";
  
function Navbar() {

    const connectWallet = () => {
      if(window.ethereum){
        // Do something 
        window.ethereum.request({method:'eth_requestAccounts'})
          .then(res=>{
                  // Return the address of the wallet
                  setAddress(res[0]);
          })
      }else{
        alert("install metamask extension!!")
      }
    }

    const [address, setAddress] = useState('')

    return (
        <div>
          <nav className="bg-white border-gray-200 px-2 sm:px-2 py-4  dark:bg-[#232323] border-b">
            <div className="px-14 flex flex-wrap items-center justify-between mx-auto">
              <a href="#" className="flex items-center">
                  {/* <img src="img/logo/logo.svg" className=" mr-3 " alt="BlockPortal Logo" width="50px"/> */}
                  <span className="self-center text-xl font-500 whitespace-nowrap dark:text-white">Block Portal <small className="ml-2"> (BETA phase1)</small></span>
              </a>
              <div className="justify-content flex items-center">
                <Switcher/>
                {address && (<span className="ml-4 text-black dark:text-white">{address}</span>)}
                {!address && (
                <a href="#_" className="ml-4 relative inline-flex items-center justify-start inline-block px-5 py-3 overflow-hidden font-medium transition-all bg-blue-600 rounded-full hover:bg-white group">
                <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-full"></span>
                <span className="relative w-full text-left text-white transition-colors duration-200 ease-in-out group-hover:text-blue-600"  onClick={connectWallet}>Connect Wallet</span>
                </a> )}
              </div>
            </div>
          </nav>
        </div>
    );
}
  
export default Navbar;