import React from "react";
import Navbar from "./components/layout/Navbar";
import Sidebar from "./components/layout/Sidebar";
import "./App.css";
import Main from "./components/layout/Main";
import Leftbar from "./components/layout/Leftbar";

function App() {
  return (
    <div className="bg-[#ffffff] dark:bg-[#1c1c1c]">
      <Navbar />
      <div className="hidden xl:flex w-full">
        <Sidebar />
        <div className="w-1/3">
          <Leftbar />
        </div>
        <div className="w-2/3">
          <Main />
        </div>
      </div>
      <div className="block xl:hidden w-full">
        <div className="flex w-full">
          <Sidebar />
          <Leftbar className="w-full"/>
        </div>
        <div className="w-full">
          <Main />
        </div>
      </div>
    </div>
  );
}

export default App;
