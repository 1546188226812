import React from "react";

function Main() {
  return (
    <div className="min-h-screen">
      <div className="flex dark:bg-[#1c1c1c] px-4 py-2 h-24 ">
        <div class="flex grid grid-cols-4  gap-4 w-full">
          <div className="bg-[#ffc736] rounded-xl"></div>
          <div className="bg-[#ff7070] rounded-xl"></div>
          <div className="bg-[#3Ad604] rounded-xl"></div>
          <div className="bg-[#e314c2] rounded-xl"></div>
        </div>
      </div>
      <div className="h-fit w-full block md:flex border-t">
        <div className="w-full md:w-2/3 dark:bg-[#1c1c1c]">
          <div className="h-20 flex p-2 px-4 text-black dark:text-white border-b items-center">
            <img
              src="img/token/usdt.png"
              className="rounded-full mr-4"
              width="50px"
            />
            <div className=" flex items-center justify-between w-full ">
              <div className="ml-2">
                <p className="text-lg font-semibold">
                  Tether <span className="ml-4 text-green-600">$ 1.93847</span>
                </p>
                <small>
                  Balance:
                  <span className="ml-3">
                    59,392,584 (
                    <span className="text-green-600"> $ 59,392,593.23 </span>)
                  </span>
                </small>
              </div>
              <div className="flex ">
                <img src="img/svg/globe-svgrepo-com.svg" width="30px" className="ml-2"/>
                <img src="img/svg/icons8-twitter-circled.svg" width="33px" className="ml-2"/>
                <img src="img/svg/icons8-facebook.svg" width="37px" className="ml-2"/>
              </div>
            </div>
          </div>
          <div className="block w-full sm:flex text-center sm:text-left justify-between text-black dark:text-white p-4">
            <div className="mb-2 sm:m-0">
              <p>Total Supply</p>
              <span>1,000,000,000</span>
              <p className="mt-2">Marktet Cap</p>
              <span className="text-green-600"> $ 59,392,593.23 </span>
            </div>
            <div className="mb-2 sm:m-0">
              <p>Tether/BNB (LP)</p>
              <span>
                0.032 BNB (
                <span className="text-green-600"> $ 59,392,593.23 </span>)
              </span>

              <p className="mt-4">LT Token Holders</p>
            </div>
            <div className="mb-2 sm:m-0">
              <a href="#">Contract</a>
              <br />
              <a href="#">Holders</a>
              <br />
              <a href="#">Transactions</a>
              <br />
            </div>
          </div>
          <div className="w-full mt-4">
            <img src="img/trade.png" className="w-full" />
          </div>
        </div>
        <div className="w-full md:w-1/3 dark:bg-[#282727] text-black dark:text-white border-l">
          <div className="p-4 h-20 flex items-center border-b justify-between">
            <div>
              <p className="text-lg font-medium">
                My Wallet: <span className="text-green-600"> $ 52,593.23 </span>
              </p>
            </div>
            <a
              href="#_"
              class="relative inline-flex items-center px-10 py-1 overflow-hidden text-lg font-medium text-blue-600 border-2 border-blue-600 rounded-full hover:text-white group hover:bg-gray-50"
            >
              <span class="absolute left-0 block w-full h-0 transition-all bg-blue-600 opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
              <span class="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
              </span>
              <span class="relative">Refresh</span>
            </a>
          </div>
          <div className="h-20 flex justify-center items-center">
            <label htmlFor="simple-search" className="sr-only">
              Search
            </label>
            <div className="relative w-full px-4  ">
              <div className="absolute inset-y-0 left-4 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search"
                required
              />
            </div>
          </div>
          <div className="p-4 flex justify-between">
            <div>
              <p>Token</p>
              <p>TETHER</p>
            </div>
            <div>
              <p>Balance</p>
              <p>
                <span className="">
                  59,392,584 (
                  <span className="text-green-600"> $ 59,392,593.23 </span>)
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
