import React from "react";

function Leftbar() {
  return (
      <div className="h-full  flex w-full" aria-label="Sidebar">
        <div className=" border-r h-full min-h-screen py-2 w-full dark:bg-[#232323]">
          <div className="h-20 flex justify-center items-center">
            <label htmlFor="simple-search" className="sr-only">
              Search
            </label>
            <div className="relative w-full px-4  ">
              <div className="absolute inset-y-0 left-4 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search"
                required
              />
            </div>
          </div>
          <div>
            <div>
              <ul>
                <li className="h-24 bg-[] flex m-auto  items-center px-6 py-2  cursor-pointer text-base font-normal text-white bg-[#0067C6] dark:bg-[#203049]">
                  <img
                    src="img/token/usdt.png"
                    className="rounded-full mr-4"
                    width="50px"
                  />
                  <div className=" flex items-center justify-between w-full ">
                    <div className="ml-2">
                      <p className="text-lg">Tether</p>
                      <small className="word-wrap ">0x4F30d841795A5Fa9FcDE37cDEc2eCa1bb9bcE2Ec</small>
                    </div>
                    <p className="float-right right border py-1 px-2 rounded-full text-sm items-center">
                      BNB Chain
                    </p>
                  </div>
                </li>
                <li className="h-24 bg-[] flex m-auto  items-center px-6 py-2  cursor-pointer text-base font-normal text-black dark:text-white hover:bg-[#0067C6] hover:text-white dark:hover:bg-[#203049]">
                  <img
                    src="img/token/usdc.png"
                    className="rounded-full mr-4"
                    width="50px"
                  />
                  <div className=" flex items-center justify-between w-full ">
                    <div className="ml-2">
                      <p className="text-lg">Tether</p>
                      <small>0x4F30d841795A5Fa9FcDE37cDEc2eCa1bb9bcE2Ec</small>
                    </div>
                    <p className="float-right right border py-1 px-2 rounded-full text-sm items-center">
                      BNB Chain
                    </p>
                  </div>
                </li>
                <li className="h-24 bg-[] flex m-auto  items-center px-6 py-2  cursor-pointer text-base font-normal text-black dark:text-white hover:bg-[#0067C6] hover:text-white dark:hover:bg-[#203049]">
                  <img
                    src="img/token/tron.png"
                    className="rounded-full mr-4"
                    width="50px"
                  />
                  <div className=" flex items-center justify-between w-full ">
                    <div className="ml-2">
                      <p className="text-lg">Tron</p>
                      <small>0x4F30d841795A5Fa9FcDE37cDEc2eCa1bb9bcE2Ec</small>
                    </div>
                    <p className="float-right right border py-1 px-2 rounded-full text-sm items-center">
                      Tron
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Leftbar;
